/*******************************
    User Variable Overrides
*******************************/

.ui.cards > .card .meta, .ui.card .meta {
	font-weight: 300;
	color: #869AB8;	
}

.ui.cards > .card > .content > .header, .ui.card > .content > .header {
	color: #869AB8;	
}

.ui.cards > .card, .ui.card {
	border-radius: 0;
}