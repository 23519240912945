/*******************************
         Site Overrides
*******************************/
.ui.form .field > label {
	font-weight: 300;
	color: #869AB8;
}

.ui.form .inline.fields > label, .ui.form .inline.fields .field > label, .ui.form .inline.fields .field > p, .ui.form .inline.field > label, .ui.form .inline.field > p {
	font-weight: 400;
}

.ui.form .fields > .field {
	align-self: flex-end;
}