/*******************************
         Site Overrides
*******************************/

.ui.vertical.menu {
	width: 100%;
}

.ui.secondary.menu {
	margin: 0;
}

.ui.secondary.menu .item{
	padding: 0.5em 1.0em;
}

.ui.secondary.vertical.menu > .item {
	margin: 0;
	border-radius: 0 !important;
	color: #3c4257;
}

.ui.vertical.menu > .active.item:first-child {
	border-radius: 0;
}

