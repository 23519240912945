/*******************************
         Site Overrides
*******************************/

small {
  font-size: 70%;
}


@text-colors: blue, green, orange, pink, purple, red, teal, yellow, black, grey, white;
.text {
    .-(@i: length(@text-colors)) when (@i > 0) {
        @c: extract(@text-colors, @i);
        &.@{c} { color: @@c }
        .-((@i - 1));
    }.-;
}