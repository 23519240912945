/*******************************
         Site Overrides
*******************************/

.ui.form .fields .field .ui.input input, .ui.form .field .ui.input input {
  outline: 0;
  border-width: 0 0 1px;
  border-radius: 0;
}

.ui[class*="left icon"].input > input {
	outline: 0;
	border-width: 0 0 1px;
	border-radius: 0;
}